
import { handleActions } from 'redux-actions';

const initInfo = {}
const commonInfo = handleActions({
    'SAVE_USER': (state, action) => { 
        return{ ...state, ...action.payload}
    }
    // ({ ...state, ...action.payload })
}, initInfo)

export default commonInfo