export const initInfo = {
     info:{}
}
const visualReducer = (state = initInfo, action) => {
    switch (action.type) {
        case 'visual_info':
            return { ...state };
        default:
            return state
    }
}
export default visualReducer