export const initInfo = {
    id: "1",
    page: null,

    modelRecord: { ModelList: [], count: 0, current: 1 },
}
const clearUpReducer = (state = initInfo, action) => {
    switch (action.type) {
        case "record_clearUp":
            return { ...state, ...action.item };
        case "record_clearUp2":
            return { ...state, ...action.item };
        case 'record_init':
            return { ...state };
        case 'record_fix':
            return { ...state, ...action.item };
        case 'record_fix_Entity':
            return { ...state, ...action.item }
        case 'record_fix_Visual':
            return { ...state, ...action.item }
        default:
            return state
    }
}
export default clearUpReducer