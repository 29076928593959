import React from 'react';
import { Modal, message } from 'antd';
// //import urlSrc from '../../pages/assets/active.svg'
import  './index.less'

function CResult(props) {
    const { type, title, content, onOk } = props
    let result = <></>
    if (type === 'SUCCESS') {
        result = message.success(`${content ? content : '提交成功'}`, 3)
    } else if (type === 'ERROR') {
        result = Modal.error({
            title: title || '操作失败',
            content: content || '',
        });
    } else if (type === 'WARN') {
        result = Modal.warning({
            title: title || '温馨提示',
            content: content || '',
        });
    }else if (type === 'INFO') {
        result = Modal.info({
            title: title ,
            content: content || '',
        });
    } else if (type === 'CONFIRM') {
        result = Modal.confirm({
            //icon: <img alt="123" src={urlSrc} width={20} height={20} />,
            // <div style={{ width: 20, height: 20, background: 'red' }} />
            //  </div>,
            // title: title || '温馨提示',
            // title: <span style={{ display: 'flex' }}>
            //     <div style={{ width: 20, height: 20, background: 'red' }} />
            //     <div>{title || '温馨提示'} </div>
            // </span>,
            title: title || '温馨提示',
            content: content || '',
            onOk() {
                if (onOk) {
                    onOk()
                } else {
                    console.log("ssss")
                    return false
                }

            },
        });
    } else {
        return true;
    }
    return (<>{result}</>)
}
export default CResult

// export const cTip = ({ type, title, content, onOk }) => {
//     console.log("ok", onOk)
//     switch (type) {
//         case 'SUCCESS':
//             return message.success(`提交成功`, 3);
//         case 'ERROR':
//             return Modal.error({
//                 title: title || '提交失败',
//                 content: content || '',
//             });
//         case 'WARN':
//             return Modal.warning({
//                 title: title || '温馨提示',
//                 content: content || '',
//             });

//         case 'CONFIRM':
//             return Modal.confirm({
//                 title: title || '温馨提示',
//                 content: content || '',
//                 onOk() { 
//                     if(onOk){
//                         onOk()
//                     }else{
//                         console.log("ssss")
//                         return false
//                     }

//                  },
//                  onCancel(){
//                     console.log("FFFFF")
//                  }
//             });
//         default:
//             return;

//     }
// }