import { handleActions } from 'redux-actions';

// export const initInfo = {
//     dataInfo: { dataList: [], count: 0, current: 1 },
// }
// const iotInfo = (state = initInfo, action) => {
//     switch (action.type) {
//         case 'IOT_INIT':
//             return {
//                 ...state
//             }
//         case 'IOT_GET_LIST':
//             return {
//                 ...state,
//                 ...{ dataInfo: { ...{ dataList: action.dataList, count: action.count, current: action.current } } }
//             }
//         case 'IOT_FIX':
//             return {
//                 ...state,
//                 ...action.item
//             }
//         default:
//             return state
//     }
// }
// export default iotInfo



const commonInfo = handleActions({
    'IOT_DICT_LIST': (state, action) => {
        return { ...state, ...action.payload }
    },
    'GET_IOT_LIST': (state, action) => {

        return { ...state, ...action.payload }
    },
    'GET_IOT_DETAIL': (state, action) => ({ ...state, detailRecord: action.payload })
}, {})

export default commonInfo