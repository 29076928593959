import { createAction, createActions } from 'redux-actions';
import actionTypes from './actionType';

export const saveUser = createAction('SAVE_USER')

export const getIotDict = createAction('IOT_DICT_LIST')
export const getIotList =  createAction('GET_IOT_LIST')
export const getIotDetail =  createAction('GET_IOT_DETAIL')

//以下测试
export default createActions({
    [actionTypes.BOOK_LIST_GET]: () => console.log("eeee"),
    [actionTypes.BOOK_DELETE]: () => console.log("cccceeee")
});