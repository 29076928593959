import { axiosCongig1 as request } from 'request';
/*
  [url,method,param]
  method:默认get 可不传
  param:默认{}
*/
export const getUserInfo = () => request('identityaccess-api/user/userinfo')
export const getMyInfo = (id) => request(`hrcore-api/person/${id}`)
export const getDictDataquery=()=> request(`identityaccess-api/dict/alllist`,'POST')         
export const getregionprovinces=()=> request(`identityaccess-api/region/provinces`,'GET')     
export const getregion=()=> request(`identityaccess-api/regions`,'GET')    
export const getregionparentCode = (code) => request(`identityaccess-api/region/parentCode/${code}`)






