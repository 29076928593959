
import { combineReducers } from 'redux'
import authiortyReducer from './authiortyReducer';
import iotInfo from './iotReducer';
import CommonInfo from './common';
import digitalTwinReducer from './digitalTwinReducer'
import clearUpReducer from "./clearUp"
import visual from "./visual"
import clientReducer from "./clientReducer"
import shareconfigReducer from "./shareconfigReducer"
import operationsManagementReducer from "./operationsManagementReducer"
import energyReducer from "./energyReducer"
import materialReducer from "./materialReducer"
export default combineReducers({
    authiortyReducer,
    iotInfo,
    CommonInfo,
    digitalTwinReducer,
    clientReducer,
    shareconfigReducer,
    clearUpReducer,
    operationsManagementReducer,
    energyReducer,
    materialReducer,
    visual
})