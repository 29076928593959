export const initInfo = {
    id: "1",
    page: null,

    repairRecord: { repairList: [], count: 0, current: 1 },
    planRecord: { planList: [], count: 0, current: 1 },
}
const opReducer = (state = initInfo, action) => {
 
    switch (action.type) {
        case 'REUSE':
            return { ...state, ...action.reuse};
        case 'RULEPERSON':
            return { ...state, ...action.preson};
        case 'DICT_ALL':
            return { ...state, ...action.dict};
        case 'AOS_STATUS':
            return { ...state, ...action.aosstatus};
        case 'record_init':
            return { ...state };
        case 'record_fix':
            return { ...state, ...action.item };
        case 'record_fix_Entity':
            return {...state, ...action.item}
        case 'record_fix_Visual':
            return {...state, ...action.item}
       case 'record_fix_Ontology':
            return { ...state, ...action.item }
        case 'meeting_view':
           return {...state, ...action.item}
        default:
            return state
    }
}
export default opReducer