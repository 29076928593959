export const initInfo = {
    id: "1",
    page: null,
    departmentObj: {},
    roleList: [],
    personRecord: { personList: [], count: 0, current: 1 },
}
const authiortyReducer = (state = initInfo, action) => {
    switch (action.type) {
        case 'record_init':
            return { ...state };
        case 'record_fix':
            return { ...state, ...action.item };
        // case 'record_list':
        //     return { ...state, ...action.item }
        default:
            return state
    }
}
export default authiortyReducer