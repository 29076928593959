export const initInfo = {
    id: "1",
    page: null,

    materialRecord: { materialList: [], count: 0, current: 1 },
}
const materialReducer = (state = initInfo, action) => {
 
    switch (action.type) {
        case 'ROLELIST':
            return { ...state, ...action.role};
        case 'address_fix':
            return { ...state, ...action.address};
        case 'DICT_ALL':
            return { ...state, ...action.dict};
        case 'record_init':
            return { ...state };
        case 'record_fix':
            return { ...state, ...action.item };
        case 'record_fix_classify':
            return {...state, ...action.item}
        case 'record_fix_Visual':
            return {...state, ...action.item}
       case 'record_fix_Ontology':
                return {...state, ...action.item}
        default:
            return state
    }
}
export default materialReducer