import React,{useState} from 'react';
import { Popover } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import classStyle from './index.less';

function CPopover(props) {
    const { info, text, trigger = "click", placement = "bottom", contens = [], } = props;
    const [open, setOpen] = useState(false);
    const poppverContent = []
    contens.map((item, index) => poppverContent.push(<div key={index} className={classStyle.popDiv} >{item}</div>))

    return (
        <Popover trigger={trigger}
            placement={placement}
            title={false}
            visible={open}
            content={<div onClick={()=>{
                console.log(3254543656);
                setOpen(false)
            }}>{poppverContent}</div> }
            onVisibleChange={(newOpen)=>{
                console.log(newOpen,9999);
                setOpen(newOpen)
            }}
        >
            {
                info ?
                    info :
                    <div className={classStyle.info} >{text || <EllipsisOutlined style={{ color: '#1664FF' }} />}</div>
            }

        </Popover>
    )
}
export default CPopover