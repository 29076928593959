
import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
//import { componentrouterList } from './match'

const Workbench = lazy(() => import(/* webpackChunkName: 'myself' */'../pages/workbench/workbench/index'));

const Myself = lazy(() => import(/* webpackChunkName: 'myself' */'../pages/authiory/account/mySelf'));
const Visual= lazy(() => import(/* webpackChunkName: 'role' */'../pages/visual/index'));//视觉独立页面
const NotFound = lazy(() => import(/* webpackChunkName: 'found' */'../pages/NotFound'));
const Deparment = lazy(() => import(/* webpackChunkName: 'deparment' */'../pages/authiory/deparment'));
const Role = lazy(() => import(/* webpackChunkName: 'role' */'../pages/authiory/role'));
const Function = lazy(() => import(/* webpackChunkName: 'role' */'../pages/iot/function'));
const Driver = lazy(() => import(/* webpackChunkName: 'driver' */'../pages/iot/driver'));
const Device = lazy(() => import(/* webpackChunkName: 'device' */'../pages/iot/device'));
const IotOverview=lazy(() => import(/* webpackChunkName: 'device' */'../pages/iot/overview'));
// const Device = lazy(() => import(/* webpackChunkName: 'device' */'../pages/diagnose/rule'));

const Ontology = lazy(() => import(/* webpackChunkName: 'role' */'../pages/digitalTwin/ontology'));
const ModelLibrary = lazy(() => import(/* webpackChunkName: 'role' */'../pages/digitalTwin/modelLibrary'));
const Twinpandect = lazy(() => import(/* webpackChunkName: 'role' */'../pages/digitalTwin/twinbody/index'));
const Twinbody = lazy(() => import(/* webpackChunkName: 'role' */'../pages/digitalTwin/twinconfig/index'));
const Management = lazy(() => import(/* webpackChunkName: 'role' */'../pages/digitalTwin/visual/index'));

const DiagnoseRule = lazy(() => import(/* webpackChunkName: 'diagnose_rule' */'../pages/diagnose/rule_management'));
const DiagnoseModel = lazy(() => import(/* webpackChunkName: 'diagnose_model' */'../pages/diagnose/model_management'));
const DiagnoseManagement = lazy(() => import(/* webpackChunkName: 'diagnose_management' */'../pages/diagnose/diagnose_management'));

const LinkAgeEvent = lazy(() => import(/* webpackChunkName: 'linkage_event' */'../pages/linkage/event-management'));
const LinkageManagement = lazy(() => import(/* webpackChunkName: 'linkage_management' */'../pages/linkage/linkage-management'));

const ThirdPartyApplication = lazy(() => import(/* webpackChunkName: 'third_party_application' */'../pages/thirdRegister/application'));

const NoticeContact = lazy(() => import(/* webpackChunkName: 'notice_contact' */'../pages/notice/contact_management'));
const NoticeLog = lazy(() => import(/* webpackChunkName: 'notice_log' */'../pages/notice/log_management'));
const NoticeModel = lazy(() => import(/* webpackChunkName: 'notice_model' */'../pages/notice/model_management'));

const SetUp = lazy(() => import(/* webpackChunkName: 'clear-set' */'../pages/clear/setup/index')); //洁净设置页面
const Insight = lazy(() => import(/* webpackChunkName: 'clear-enviroment' */'../pages/Insight/EnviCount/index')); //洞察总览
const ClearEnvirDiagnose = lazy(() => import(/* webpackChunkName: 'clear-enviroment' */'../pages/Insight/EnviClear/index')); //洁净环境诊断
// const DeviceEnvirDiagnose = lazy(() => import(/* webpackChunkName: 'clear-enviroment' */'../pages/Insight/EnviDevice/index')); //设备环境诊断
const Monitor = lazy(() => import(/* webpackChunkName: 'role' */'../pages/clear/monitor/index')); // 洁净监测
const Report = lazy(() => import(/* webpackChunkName: 'role' */'../pages/clear/report/index')); //报告
const Alarm = lazy(() => import(/* webpackChunkName: 'role' */'../pages/clear/alarm/index')); //报警

const AppPortal = lazy(() => import(/* webpackChunkName: 'app-portal' */'../pages/home/appPortal')); //门户
const HomeTodo = lazy(() => import(/* webpackChunkName: 'home-todo' */'../pages/home/todo')); //应用


const HvacSetup = lazy(() => import(/* webpackChunkName: 'role' */'../pages/hvac/set/index')); //暖通设置
const HvacMonitorIng = lazy(() => import(/* webpackChunkName: 'role' */'../pages/hvac/monitor/index')); //暖通设置
const HvacIslight = lazy(() => import(/* webpackChunkName: 'role' */'../pages/hvac/Insight/index')); //暖通仪表盘
const HvacAlarm = lazy(() => import(/* webpackChunkName: 'role' */'../pages/hvac/alarm/index')); //暖通报警


const InstrumentSet = lazy(() => import(/* webpackChunkName: 'role' */'../pages/instrument/set/index')); //仪器设置
const InstrumentMonitor = lazy(() => import(/* webpackChunkName: 'role' */'../pages/instrument/monitor/index')); //仪器设置

const AssetSeting= lazy(() => import(/* webpackChunkName: 'role' */'../pages/assetsManages/setting/')); //资产设置
//const AssetsPurchase = lazy(() => import(/* webpackChunkName: 'role' */'../pages/assetsManages/assetsPurchase/index')); //资产申购
const InventoryDatabase = lazy(() => import(/* webpackChunkName: 'role' */'../pages/assetsManages/inventoryDatabase/index')); //资产管理-登记
// const Receive = lazy(() => import(/* webpackChunkName: 'role' */'../pages/assetsManages/receiveAndReturn/index')); //资产管理-设置
const Inventory = lazy(() => import(/* webpackChunkName: 'role' */'../pages/assetsManages/inventory/index')); //资产管理-登记

const ClientInformation = lazy(() => import(/* webpackChunkName: 'role' */'../pages/client/clientInformation/index')); //客户管理  客户信息
const VisitorLog = lazy(() => import(/* webpackChunkName: 'role' */'../pages/client/visitorLog/index')); //客户管理  访客记录
const Miniconfig = lazy(() => import(/* webpackChunkName: 'role' */'../pages/miniconfig/miniConlting/index')); //小程序配置  咨询管理
const MiniNotice = lazy(() => import(/* webpackChunkName: 'role' */'../pages/miniconfig/miniNotice/index')); //小程序配置  公告管理
const Calender = lazy(() => import(/* webpackChunkName: 'role' */'../pages/miniconfig/calender/index')); //小程序配置  日历信息
const UserFeedback = lazy(() => import(/* webpackChunkName: 'role' */'../pages/miniconfig/userFeedback')); //小程序配置  用户反馈
const QuotaManagement = lazy(() => import(/* webpackChunkName: 'role' */'../pages/miniconfig/quotaManagement/index')); //小程序配置  额度管理
const MonthlyConsumptionStatistics = lazy(() => import(/* webpackChunkName: 'role' */'../pages/miniconfig/monthlyConsumptionStatistics/index')); //小程序配置  当月消耗统计
const HistoricalConsumptionStatistics = lazy(() => import(/* webpackChunkName: 'role' */'../pages/miniconfig/historicalConsumptionStatistics/index')); //小程序配置  历史消耗统计

const ConferenceRoom = lazy(() => import(/* webpackChunkName: 'role' */'../pages/shareconfig/meeting/room/index')); //共享管理  会议室资源

const MeetingReservation = lazy(() => import(/* webpackChunkName: 'role' */'../pages/shareconfig/meeting/order/index')); //共享管理  会议室预约
const InstrumentResource = lazy(() => import(/* webpackChunkName: 'role' */'../pages/shareconfig/Instrument/room/index')); //共享管理  会议室资源

const InstrumentReservation = lazy(() => import(/* webpackChunkName: 'role' */'../pages/shareconfig/Instrument/order/index')); //共享管理  会议室预约
const Strategies = lazy(() => import(/* webpackChunkName: 'role' */'../pages/instrumentManagement/strategies/index')); // 仪器预约-策略
const Statistics = lazy(() => import(/* webpackChunkName: 'role' */'../pages/instrumentManagement/statistics/index')); // 仪器预约-统计
const InDatabase = lazy(() => import(/* webpackChunkName: 'role' */'../pages/instrumentManagement/inventoryDatabase/index')); // 仪器预约-登记
const Spacemanagement = lazy(() => import(/* webpackChunkName: 'role' */'../pages/space/spacemanagement/index')); // 空间管理
const BulletinBoard = lazy(() => import(/* webpackChunkName: 'role' */'../pages/instrumentManagement/BulletinBoard/index')); // 仪器预约-策略


const RepairOrder= lazy(() => import(/* webpackChunkName: 'role' */'../pages/operationsManagement/repairOrder/index')); // 维修管理-维修工单
const InspectionItem= lazy(() => import(/* webpackChunkName: 'role' */'../pages/operationsManagement/inspection/item')); // 巡检管理-巡检项
const InspectionPlan= lazy(() => import(/* webpackChunkName: 'role' */'../pages/operationsManagement/inspection/plan')); // 巡检管理-巡检计划
const InspectionOrder= lazy(() => import(/* webpackChunkName: 'role' */'../pages/operationsManagement/inspection/order')); // 巡检管理-巡检工单
const UpkeepItem= lazy(() => import(/* webpackChunkName: 'role' */'../pages/operationsManagement/upkeep/item')); // 巡检管理-保养项
const UpkeepPlan= lazy(() => import(/* webpackChunkName: 'role' */'../pages/operationsManagement/upkeep/plan')); // 巡检管理-保养计划
const UpkeepOrder= lazy(() => import(/* webpackChunkName: 'role' */'../pages/operationsManagement/upkeep/order')); // 巡检管理-保养工单

const CalibrateItem= lazy(() => import(/* webpackChunkName: 'role' */'../pages/operationsManagement/calibrate/item')); // 巡检管理-校准项
const CalibratePlan= lazy(() => import(/* webpackChunkName: 'role' */'../pages/operationsManagement/calibrate/plan')); // 巡检管理-校准计划
const CalibrateOrder= lazy(() => import(/* webpackChunkName: 'role' */'../pages/operationsManagement/calibrate/order')); // 巡检管理-校准工单

const EneryOverview = lazy(() => import(/* webpackChunkName: 'role' */'../pages/energy/overview/index')); // 能耗管理-总览
const EnerySginin = lazy(() => import(/* webpackChunkName: 'role' */'../pages/energy/sginin/index')); // 能耗管理-登记
const EneryMonitor= lazy(() => import(/* webpackChunkName: 'role' */'../pages/energy/monitor/index')); // 能耗管理-监测
const EneryUnit= lazy(() => import(/* webpackChunkName: 'role' */'../pages/energy/eneryUnit/index')); // 能耗管理-能耗单元
const EneryDevice= lazy(() => import(/* webpackChunkName: 'role' */'../pages/energy/eneryDevice/index')); // 能耗管理-能耗设备
const EneryAlarm= lazy(() => import(/* webpackChunkName: 'role' */'../pages/energy/alarm/index')); // 能耗管理-能耗设备
const Eneryreportforms= lazy(() => import(/* webpackChunkName: 'role' */'../pages/energy/reportforms/index')); // 能耗管理-统计报表


const AlarmRule= lazy(() => import(/* webpackChunkName: 'role' */'../pages/alarm/rule/index')); // 报警规则-报警规则
const HolidayManagement= lazy(() => import(/* webpackChunkName: 'role' */'../pages/common/holidays/index')); //通用配置-节假日管理
const InstrumentClass= lazy(() => import(/* webpackChunkName: 'role' */'../pages/common/instrumentClass/index')); //通用配置-仪器分类管理
const UnitManagement= lazy(() => import(/* webpackChunkName: 'role' */'../pages/common/unit/index')); //通用配置-单位管理
const TypeManagement= lazy(() => import(/* webpackChunkName: 'role' */'../pages/common/types/index')); //通用配置-单位管理
const MaterialArrangement= lazy(() => import(/* webpackChunkName: 'role' */'../pages/material/set/index')); //物料管理-物料设置
const Storagelocation= lazy(() => import(/* webpackChunkName: 'role' */'../pages/material/storagelocation/index')); //物料管理-物料设置
const MaterialEntryManagement= lazy(() => import(/* webpackChunkName: 'role' */'../pages/material/materialEntry/index')); //物料管理-物料设置
function Router(props) {
    let { routers = [],current } = props
    let routerList = []
 
    routers.map((item) => {
        item.path = `/${item.location}`
        switch (item.location) {
            case 'role':
                return routerList.push({ ...item, element: <Role /> })
            case 'department':
                return routerList.push({ ...item, element: <Deparment /> })
            case 'workbench':
                return routerList.push({ ...item, element: <Workbench /> })
            case 'visual':
                return routerList.push({ ...item, element: <Visual /> })
            case 'myself':
                return routerList.push({ ...item, element: <Myself /> })
            case 'iotOverview':
                return routerList.push({ ...item, element: <IotOverview /> })   
            case 'functionManagement':
                return routerList.push({ ...item, element: <Function /> })
            case 'dirverManagement':
                return routerList.push({ ...item, element: <Driver /> })
            case 'deviceManagement':
                return routerList.push({ ...item, element: <Device /> })
            case 'ontology':
                return routerList.push({ ...item, element: <Ontology /> })
            case 'modelLibrary':
                return routerList.push({ ...item, element: <ModelLibrary /> })
            case 'twinpandect':
                return routerList.push({ ...item, element: <Twinpandect /> })
            case 'twinBodyManagement':
                return routerList.push({ ...item, element: <Twinbody /> })
            case 'management':
                return routerList.push({ ...item, element: <Management /> })

            case 'diagnoseRuleManagement':
                return routerList.push({ ...item, element: <DiagnoseRule /> })
            case 'diagnoseModelManagement':
                return routerList.push({ ...item, element: <DiagnoseModel /> })
            case 'diagnoseManagement':
                return routerList.push({ ...item, element: <DiagnoseManagement /> })

            case 'linkageEventManagement':
                return routerList.push({ ...item, element: <LinkAgeEvent /> })
            case 'linkageManagement':
                return routerList.push({ ...item, element: <LinkageManagement /> })

            case 'thirdPartyApplication':
                return routerList.push({ ...item, element: <ThirdPartyApplication /> })
            case 'appPortal':
                return routerList.push({ ...item, element: <AppPortal /> })
            case 'todo':
                return routerList.push({ ...item, element: <HomeTodo /> })

            case 'clearsetup':
                return routerList.push({ ...item, element: <SetUp /> })
            case 'clearreport':
                return routerList.push({ ...item, element: <Report /> })
            case 'clearmonitor':
                return routerList.push({ ...item, element: <Monitor /> })
            case 'clearalarm':
                return routerList.push({ ...item, element: <Alarm /> })
            case 'envirMonitorCount':
                return routerList.push({ ...item, element: <Insight /> })
            case 'clearEnvirDiagnose':
                return routerList.push({ ...item, element: <ClearEnvirDiagnose /> })
            // case 'deviceEnvirDiagnose':
            //     return routerList.push({ ...item, element: <DeviceEnvirDiagnose /> })
            case 'todo2':
                return routerList.push({ ...item, element: <NoticeContact /> })
            case 'todo3':
                return routerList.push({ ...item, element: <NoticeLog /> })
            case 'todo4':
                return routerList.push({ ...item, element: <NoticeModel /> })
            case 'hvacSetup':
                    return routerList.push({ ...item, element: <HvacSetup /> }) 
           case 'hvacMonitoring':
                return routerList.push({ ...item, element: <HvacMonitorIng /> })
            case 'hvacpanel':
                return routerList.push({ ...item, element: <HvacIslight /> })
            case 'havcalarm':
                return routerList.push({ ...item, element: <HvacAlarm /> })
            case 'instrumentset':
                return routerList.push({ ...item, element: <InstrumentSet /> })
            case 'instrumentmonitor':
                return routerList.push({ ...item, element: <InstrumentMonitor /> })
      
            case 'register':
                return routerList.push({ ...item, element: <InventoryDatabase /> })
            case 'setting':
                return routerList.push({ ...item, element: <AssetSeting/> })
             case 'Inventory':
                return routerList.push({ ...item, element: <Inventory /> })
            case 'custInformation':
                    return routerList.push({ ...item, element: <ClientInformation /> })
            case 'visitorLog':
                return routerList.push({ ...item, element: <VisitorLog /> })
            case 'miniconlting':
                return routerList.push({ ...item, element: <Miniconfig /> })
            
           case 'miniNotice':
                
                // return routerList.push({ ...item, element: <MonthlyConsumptionStatistics /> })
                return routerList.push({ ...item, element: <MiniNotice /> })
            case 'quotaManagement':
                return routerList.push({ ...item, element: <QuotaManagement /> })
            case 'monthlyConsumptionStatistics':
                return routerList.push({ ...item, element: <MonthlyConsumptionStatistics /> })
            case 'historicalConsumptionStatistics':
                return routerList.push({ ...item, element: <HistoricalConsumptionStatistics /> })
            case 'calender':
                return routerList.push({ ...item, element: <Calender /> })
                //UserFeedback
            case 'userFeedback':
                return routerList.push({ ...item, element: <UserFeedback /> })
            case 'conferenceRoom':
                return routerList.push({ ...item, element: <ConferenceRoom /> })
             
            case 'meetingReservation':
                return routerList.push({ ...item, element: <MeetingReservation /> }) 
            case 'InstrumentReservation':
                    return routerList.push({ ...item, element: <InstrumentReservation /> })
                 
            case 'InstrumentResource':
                return routerList.push({ ...item, element: <InstrumentResource /> }) 
            //运营-仪器管理
            // 仪器管理-策略
            case 'strategies': 
                return routerList.push({ ...item, element: <Strategies /> }) 
            case 'statistics':
                return routerList.push({ ...item, element: <Statistics /> }) 
             case 'bulletinBoard': 
                return routerList.push({ ...item, element: <BulletinBoard /> }) 
            case 'spacemanagement':
                    return routerList.push({ ...item, element: <Spacemanagement /> }) 
            case 'InstrumentRegistration':
                    return routerList.push({ ...item, element: <InDatabase /> }) 
            case 'repairOrder':
                return routerList.push({ ...item, element: <RepairOrder /> }) 
            case 'inspectionItem':
                return routerList.push({ ...item, element: <InspectionItem /> }) 
            case 'inspectionPlan':
                return routerList.push({ ...item, element: <InspectionPlan /> }) 
            case 'inspectionOrder':
                return routerList.push({ ...item, element: <InspectionOrder /> }) 
            case 'upkeepltem':
                return routerList.push({ ...item, element: <UpkeepItem /> }) 
            case 'upkeepPlan':
                return routerList.push({ ...item, element: <UpkeepPlan /> }) 
            case 'upkeepOrder':
                return routerList.push({ ...item, element: <UpkeepOrder /> }) 
            case 'calibrateltem':
                return routerList.push({ ...item, element: <CalibrateItem /> }) 
            case 'calibratePlan':
                return routerList.push({ ...item, element: <CalibratePlan /> }) 
            case 'calibrateOrder':
                return routerList.push({ ...item, element: <CalibrateOrder /> }) 
            
            case 'enerySginin':
                return routerList.push({ ...item, element: <EnerySginin /> }) 
            case 'eneryOverview':
                return routerList.push({ ...item, element: <EneryOverview /> }) 
            case 'eneryMonitor':
                return routerList.push({ ...item, element: <EneryMonitor /> }) 
            case 'eneryUnit':
                return routerList.push({ ...item, element: <EneryUnit /> }) 
            case 'eneryDevice':
                return routerList.push({ ...item, element: <EneryDevice /> })
            case 'eneryreportforms':
                return routerList.push({ ...item, element: <Eneryreportforms /> }) 
            
            case 'eneryAlarm':
                return routerList.push({ ...item, element: <EneryAlarm /> }) 
            case 'alarmRule':
                return routerList.push({ ...item, element: <AlarmRule /> }) 
            
            case 'holidaysManagementIndex':
                return routerList.push({ ...item, element: <HolidayManagement /> }) 
            case 'unitManagement':
                return routerList.push({ ...item, element: <UnitManagement /> }) 
            case 'typeManagement':
                return routerList.push({ ...item, element: <TypeManagement /> }) 
            case 'instrumentClass':
                return routerList.push({ ...item, element: <InstrumentClass /> }) 
        //
            case 'storagelocation':
                return routerList.push({ ...item, element: <Storagelocation /> })
            case 'materialArrangement':
                return routerList.push({ ...item, element: <MaterialArrangement /> }) 
         
            case 'materialEntryManagement':
                return routerList.push({ ...item, element: <MaterialEntryManagement /> }) 
            default:
                return routerList.push({ ...item, element: <NotFound /> })
        }
    })
    // console.log(routers,676767);
    // console.log(current,999999);
    // console.log(routerList.find(item => item.location === current),676767);
    const defaultrouter=routerList.find(item => item.location === current)
    return (
        <Suspense fallback={<div>页面正在加载中,请稍等...</div>}>
            <Routes>
                {
                    routerList.length > 0 && <Route path="/" index element={defaultrouter.element} />
                }
                {
                    routerList.map(item => <Route key={item.path} path={item.path} element={item.element} />)
                }
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    )
}
export default Router;



