/* eslint-disable */
const BOOK_LIST_GET = 'BOOK_LIST_GET';
const BOOK_DELETE = 'BOOK_DELETE';

export default {
    /**
     *  获取书籍列表
     */
    BOOK_LIST_GET,
    /**
     *  删除书籍
     */
    BOOK_DELETE,
};