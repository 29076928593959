/* eslint-disable */
import { saveUser } from 'actions/common';
import { Layout, Menu } from "antd";
import { getMyInfo, getUserInfo } from 'api/common';
import { SystemName,SystemLogo } from '../../../public/static/config';
import CPopover from 'co-popover';
import CResult from 'co-result';
import homeSvg from 'pages/assets/home.svg';
import React, { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
// import logoPng from '../../../public/static/img/logo2.svg';
// import logoPng from '../assets/zclogo.svg';
import person from '../assets/auth/person.svg';
import background from '../assets/auth/background.svg';
import esc from '../assets/auth/esc.svg';
import Router from '../../router';
import { PERMIT_STRING_LIST } from '../../util/source';
import iconStyle from './icon.less';
import style from './index.less';
const { Header, Sider, Content } = Layout;
// import { testObj } from './testData';

function PageLayout(props) {

    const childRef = useRef();
    const positionRef = useRef();
    const itemsRef = useRef([]);
    const location = useLocation();

    const navigate = useNavigate();
    const [state, setState] = useState({
        loginUserInfo: {},
        authority: false,
        pageList: [],
        menuList: [],
        routers: [],
        current: null,
        openKeys: [],
        headerCurrent: '',
        headerList: [],
        currentList: [],
        preLocation: '',//记录当前current 在后端页面返回时使用
    })
    const [scrolled, setScrolled] = useState(0)
    const [data, setData] = useState([])
    const { loginUserInfo, authority, pageList, menuList, routers, current, openKeys = [], headerCurrent, headerList, currentList, preLocation } = state
    const { dispatch } = props

    // const changeItem = (item) => {
    //     const mapObj = {
    //         resCode: "rescode",
    //         resName: "resname",
    //         resType: "restype",
    //         parentId: "parentid",
    //         subsysId: "subsysid",
    //     };
    //     const newObj = Object.fromEntries(Object.entries(item).map(([k, v]) => [mapObj[k] || k, v]))
    //     return newObj
    // }
    const getCuurentIds = (key, alList) => {
        let allChildKeys = []
        const getIds = (item) => {
            item.rescode = item.resCode
            item.resname = item.resName
            item.parentid = item.parentId
            item.restype = item.resType
            item.subsysid = item.subsysId

            item.label = item.level === 3 ? <div><Link to={`/${item.location}`}>{item.resname}</Link></div> : <div style={{ color: '#273B45', fontSize: '16px' }}>{item.resname}</div>
            item.key = item.location
            item.icon = <div style={{ marginLeft: '12px' }}>{'\xa0'}</div> //占位 第三级
            let child = [], childKeys = []
            let objectList = alList.filter(k => k.parentId === item.rescode) || []
      
            objectList.sort((a, b) => a.seq > b.seq ? 1 : -1)
            objectList.forEach(k => {
                child.push(k)
                k.location && childKeys.push(k.location)
                k.location && allChildKeys.push(k.location)
                return true
            })
            item.childKeys = childKeys
            if (child.length > 0) {
                child.sort((a, b) => a.seq > b.seq ? 1 : -1)
                item.children = child
                child.map(k => getIds(k))
            }
        }
        getIds(key)
        key.allChildKeys = allChildKeys
    }
    const postFeatures = (apiList) => {
  
        dispatch(saveUser({ apiList }))
    }
    useEffect(() => {
        const [, currents] = location.pathname.split('/')

        if (currents !== preLocation && menuList.length > 0) {
       
            if (["visual", 'department', 'role', 'myself'].includes(currents)) {
                setState({
                    ...state,
                    current: currents,
                    preLocation: currents,
                })
            } else {
                const list = menuList.filter(item => item.allChildKeys.includes(currents))
                const currentObj = list[0]
                const headerCurrent = headerList.find(item => item.resCode === currentObj.parentId).resCode
                const currentList = menuList.filter(item => item.parentid === headerCurrent)
                setState({
                    ...state,
                    current: currents,
                    preLocation: currents,
                    headerCurrent: headerCurrent,
                    currentList: currentList,
                    openKeys: [currentObj.location]
                })
                if (!data.some(k => k.id === currents)) {
                    const refreshItem = routers.filter(item => item.location === currents) || [] //活动标签
                    let list = [...data]
                    list.unshift({ id: currents, name: refreshItem[0].resname }) //添加在最前面
                    setData(list)
                }
            }
        }
 
    }, [location])
    useEffect(() => {
        (async function () {
            // document.body.style.zoom = (window.innerWidth / window.outerWidth)
            let userInfo = await getUserInfo()
            if (userInfo) {
                let { personId, features = [], apis = [], menus = [] } = userInfo;
                if (features.length === 0 && apis.length === 0 && menus.length === 0) {
                    CResult({ type: 'INFO', title: '没有权限登陆,请联系管理员', content: '页面将在2s后跳转至登录页' })
                    setTimeout(() => {
                        localStorage.removeItem("token");
                        window.location.replace("/login.html");
                    }, 2000)
                } else {
                    if (personId) {
                        const myInfo = await getMyInfo(personId) || {}
                        let accountInfo
                        if (myInfo.id) {
                            accountInfo = { ...userInfo, ...myInfo, userId: userInfo.userId }
                        } else {
                            accountInfo = { ...userInfo, id: personId }
                        }
                        postFeatures(features)
                        // dispatch(saveUser({ userInfo: accountInfo, apiList: features }))
                        dispatch(saveUser({ userInfo: accountInfo }))
                        const headerList = menus.filter(item => item.level === 1) || []
                        headerList.sort((a, b) => a.seq > b.seq ? 1 : -1)
                   
                        let menuList1 = menus.filter(item => item.level !== 1) || []
                        const menuList = [...menuList1]
                        menuList.map(item => getCuurentIds(item, menuList1))
                        // let rlist=[]
               
                        // let level2list=menuList.filter(item => item.level === 2) || []
                        // level2list.sort((a, b) => a.seq > b.seq ? 1 : -1)
                        // console.log(level2list,8889999);
                        // level2list.forEach(item=>{
                        //     if(item.children){
                        //         item.children.sort((a, b) => a.seq > b.seq ? 1 : -1)
                        //         rlist.push(...item.children)
                        //     }
                      
                        // })
                        // console.log(rlist,8889999);
                   
                        const routers = menus.filter(item => item.level === 3) || []
             
                        const isManagement = features.some(item => item.resCode === PERMIT_STRING_LIST.backend_manager) //判断登录账号是否为管理员
                 
                        const currentPath = location && location.pathname
                        let authioryPage = false, current, openKeys, headerCurrent, currentList, preLocation
                        const [, pathname] = currentPath.split('/')
               
                        if (pathname) {
                            if (['department', 'role'].includes(pathname)) {
                                authioryPage = true
                                current = pathname
                                preLocation = '' //刷新页面才会进入这个if 所以需要重置, 由其他页面跳转则不需要
                            } else if (['myself', 'visual'].includes(pathname)) {
                                current = pathname
                                openKeys = []
                                preLocation = ''
                            } else {

                                const list = menuList.filter(item => item.allChildKeys.includes(pathname))

                                const currentObj = list[0]
                                headerCurrent = headerList.find(item => item.resCode === currentObj?.parentId)?.resCode
                                currentList = menuList.filter(item => item.parentid === headerCurrent)
                                currentList.sort((a, b) => a.seq > b.seq ? 1 : -1)
                                current = pathname
                                preLocation = pathname
                                openKeys = [currentObj?.location]

                                const refreshItem = routers.filter(item => item.location === pathname) || [] //活动标签
                                setData([{ id: pathname, name: refreshItem[0]?.resname }])
                            }
                        } else {
                        
                            headerCurrent = headerList[0].resCode
                       
                            currentList = menuList.filter(item => item.parentId === headerCurrent)
                      
                            currentList.sort((a, b) => a.seq > b.seq ? 1 : -1)
                      
                            const itemObj = currentList[0]
           
                            
                            current =itemObj.children[0].location
                         
                            preLocation = pathname
                            openKeys = [itemObj.location]
                        
                            setData([{ id: current, name: itemObj.children[0].resname }])
                        }

                        let pageLists = []
                        if (isManagement) {
                            pageLists = [ 
                                { id: 12, rescode: 12, location: 'department', resname: '部门与用户', },
                                { id: 13, rescode: 13, location: 'role', resname: '角色与权限', }
                            ]
                            routers.push(
                                { location: 'department', resName: '部门与员工', },
                                { location: 'role', resName: '角色与权限', }
                            )
                        }
                        routers.push({ location: 'myself', resName: '我的账号',})
                        routers.push({ location: 'visual', resName: '2d3d查看', })
             
                        accountInfo.isManagement = isManagement
                 
                        setState({
                            loginUserInfo: accountInfo,
                            current,
                            preLocation: preLocation,
                            headerCurrent,
                            openKeys,
                            authority: authioryPage,
                            pageList: pageLists,
                            routers,
                            menuList,
                            currentList,
                            headerList
                        })
                    }
                }
            } else {
                CResult({ type: 'INFO', title: '没有权限登陆,请联系管理员', content: '页面将在2s后跳转至登录页' })
                setTimeout(() => {
                    localStorage.removeItem("token");
                    window.location.replace("/login.html");
                }, 2000)
            }
        })()
    }, [])

    useEffect(() => {
        if (!authority) {  //动态处理currentList icon状态

            let list = currentList ? [...currentList] : []
            list.map(item => {
                item.icon = <div className={iconStyle[item.location]}>
                    <div className={`${item.childKeys.includes(current) && iconStyle[`active`]}`} />
                </div>
                item.label = <div style={{ color: item.childKeys.includes(current) ? '#0088FF' : '#273B45' }}>{item.resname}</div>
            })
            setState({ ...state, currentList: list })
        }
    }, [headerCurrent, current])

    let contents = [
        <div onClick={() => {
            setState({ ...state, current: 'myself' })
            navigate(`/myself`)
        }}><img style={{ width: '16px', height: '16px', marginRight: '8px', verticalAlign: 'middle' }} src={person} alt='账户管理'></img>账户管理</div>
    ]
    loginUserInfo.isManagement && contents.push(
        <div onClick={() => {
            setState({ ...state, current: 'department', authority: true })
            navigate(`/department`)
        }}><img style={{ width: '16px', height: '16px', marginRight: '8px', verticalAlign: 'middle' }} src={background} alt='后台管理'></img>后台管理</div>
    )
    contents.push(
        <div onClick={() => handleLogout()}><img style={{ width: '16px', height: '16px', marginRight: '8px', verticalAlign: 'middle' }} src={esc} alt='退出登录'></img>退出登录</div>
    )
    const onBack = () => {
        let currentnow
        if (preLocation) {
            currentnow = preLocation
            setState({ ...state, current: preLocation, authority: false })
        } else {
            let headerCurrentnow = headerList[0].resCode
            let currentListnow = menuList.filter(item => item.parentid === headerCurrentnow)
            const currentObj = currentListnow[0]
            let openKeysnow = [currentObj.location]
            currentnow = currentObj.children[0].location
            let preLocationNow = currentObj.children[0].location
            setData([{ id: currentListnow, name: currentObj.children[0].resname }])
            setState({ ...state, headerCurrent: headerCurrentnow, currentList: currentListnow, current: currentnow, openKeys: openKeysnow, preLocation: preLocationNow, authority: false })
        }
        navigate(`/${currentnow}`)
    }
    const handleLogout = () => {
        CResult({
            type: 'CONFIRM',
            content: `当前登录账号为:${loginUserInfo.personName},确认要退出吗?`,
            onOk: () => {
                localStorage.removeItem('token');
                window.location.replace("/login.html");
            }
        })
    }
    const getSum = (list) => {
        let count = 0
        list.forEach(item => count += itemsRef.current[`ref_${item.id}`].offsetWidth)
        return count
    }
    const compareIndex = (key) => {
        let clickIndex = data.findIndex(item => item.id === key)
        let currentIndex = data.findIndex(item => item.id === current)
        return clickIndex > currentIndex
    }
    const onItemClick = (key) => {
        if (compareIndex(key)) {
            onNextClick(key)
        } else {
            onPreviousClick(key)
        }
    }
    const onNextClick = (key) => {
        const keyIndex = data.findIndex(item => item.id === current)
        const currentKey = key || data[keyIndex + 1].id
        const list = [...data].splice(0, keyIndex + 1);

        const currentL = itemsRef.current[`ref_${currentKey}`].offsetWidth;
        const beforeL = getSum(list)
        const allL = positionRef.current.offsetWidth
        if (scrolled > 0) {
            let scrolledN = scrolled + currentL
            childRef.current.scrollLeft = scrolledN
            setScrolled(scrolledN)
        } else {
            if ((currentL + beforeL) > (allL)) {
                //ps:第一个需要滚动的item 出现向前按钮 因为向前按钮是根据scrolled设置的 此时scrolled=0 还未做setState拿到的宽度还未减需要在计算的时候减去(需要依据UI设计) 
                let scrolledN = (currentL + beforeL) - (allL)
                childRef.current.scrollLeft = scrolledN
                setScrolled(scrolledN)
            }
        }
        const cList = menuList.filter(item => item.allChildKeys.includes(currentKey)) || []
        let oKeys = [...openKeys], headerKey = cList[0].parentId, ops = {}
        if (headerKey !== headerCurrent) {//如果hearder不相等需要重新判断边栏list和头部key
            const currentLitNew = menuList.filter(item => item.parentId === headerKey)
            currentLitNew.sort((a, b) => a.seq > b.seq ? 1 : -1)
            ops.headerCurrent = headerKey
            ops.currentList = currentLitNew
        }
        if (!oKeys.includes(cList[0].location)) {
            oKeys = [...openKeys].concat([cList[0].location])
        }
        setState({ ...state, openKeys: oKeys, current: currentKey, preLocation: currentKey, ...ops })
        navigate(`/${currentKey}`)
    }
    const onPreviousClick = (key) => {
        const keyIndex = data.findIndex(item => item.id === current)
        const currentKey = key || data[keyIndex - 1].id
        if (scrolled > 0) {
            const list = [...data].splice(0, keyIndex);
            const currentL = getSum(list)
            const allL = positionRef.current.offsetWidth
            let scrolledN = 0
            if (currentL > allL) {
                scrolledN = scrolled - itemsRef.current[`ref_${currentKey}`].offsetWidth
            }
            childRef.current.scrollLeft = scrolledN
            setScrolled(scrolledN)
        }

        const cList = menuList.filter(item => item.allChildKeys.includes(currentKey)) || []
        let oKeys = [...openKeys], headerKey = cList[0].parentId, ops = {}
        if (headerKey !== headerCurrent) {//如果hearder不相等需要重新判断边栏list和头部key
            const currentLitNew = menuList.filter(item => item.parentId === headerKey)
            currentLitNew.sort((a, b) => a.seq > b.seq ? 1 : -1)
            ops.headerCurrent = headerKey
            ops.currentList = currentLitNew
        }
        if (!oKeys.includes(cList[0].location)) {
            oKeys = [...openKeys].concat([cList[0].location])
        }
        setState({ ...state, openKeys: oKeys, current: currentKey, preLocation: currentKey, ...ops })
        // setState({ ...state, current: currentKey, preLocation: currentKey })
        navigate(`/${currentKey}`)
    }
    const onRemoveItem = (id) => {
        const list = [...data]
        const index = list.findIndex(item => item.id === id)
        list.splice(index, 1)
        if (id === current) {
            if (index === 0) {
                setState({ ...state, current: list[0].id, preLocation: list[0].id })
                navigate(`/${list[0].id}`)
            } else {
                setState({ ...state, current: data[index - 1].id, preLocation: data[index - 1].id })
                navigate(`/${data[index - 1].id}`)
            }
        }
        setData(list)
    }
    const onIntoData = (item) => {
        if (!data.some(k => k.id === item.id)) {
            let list = [...data]
            list.unshift(item) //添加在最前面
            setData(list)
        } else {
            console.log(">>>>>>找到已存在的key判断距离 是否需要滚动")
        }
    }
    const onHeaderClik = (item) => {
        const headerCurrent = item.resCode
        const currentLitNew = menuList.filter(item => item.parentId === headerCurrent)
        currentLitNew.sort((a, b) => a.seq > b.seq ? 1 : -1)
        const currentObj = currentLitNew[0]
  
        const current = currentObj.children[0].location
        const openKeys = [currentObj.location]
        onIntoData({ id: current, name: currentObj.children[0].resName })
        setState({ ...state, headerCurrent, current, preLocation: current, openKeys, currentList: currentLitNew })
        navigate(`/${current}`)
    }

    return (

        <Layout className={style.layout}>
            <Header className={style.header} >
                <div className={style.left}>
                    <img alt="logo" src={SystemLogo} />
                    <div className={style.title}>{SystemName}</div>
                    {/* <div className={style.title}>生物芯片开放创新中心</div>  */}
                    {
                        ((authority && loginUserInfo.isManagement) || (current === 'myself')) && <div className={style.back} onClick={() => onBack()}><img alt='home' src={homeSvg} /><div className={style.backText}>返回工作台</div></div>
                    }
                    {
                        (!authority && current !== 'myself') && <div style={{ display: 'flex', position: 'absolute', left: '50vw', transform: 'translateX(-50%)' }}>
                            {
                                headerList && headerList.length > 0 && headerList.map(item => <div onClick={() => headerCurrent !== item.resCode && onHeaderClik(item)} style={{ marginRight: '8px', color: headerCurrent === item.resCode ? '#0088FF' : '#42555F', cursor: 'pointer', height: '64px' }} key={item.resCode}>
                                    <div style={{ padding: '10px 24px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <div className={`${iconStyle[item.resCode]}`}>
                                            <div className={`${iconStyle[headerCurrent === item.resCode && 'active']} `} />
                                        </div>
                                        <div style={{ lineHeight: '24px' }}>{item.resName}</div>
                                    </div>
                                </div>)
                            }
                        </div>
                    }
                </div>
                <div className={style.right}>
                    <CPopover text={loginUserInfo.personName && loginUserInfo.personName.substr(0, 1)} trigger={'hover'} contens={contents} />
                </div>
            </Header>
            <Layout className={style.bac}>
                {
                    !['myself', 'visual'].includes(current) && <Sider className={style.silder} width='180px'>
                        {
                            authority
                                ? pageList.map(item => <div onClick={() => setState({ ...state, current: item.location })} key={item.id} className={`${style.item} ${current === item.location && style.activeItem}`}>
                                    <Link to={`/${item.location}`}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className={iconStyle[item.location]} style={{ marginRight: '8px' }}>
                                                <div className={`${current === item.location && iconStyle[`active`]}`} />
                                            </div>
                                            {item.resname}
                                        </div>
                                    </Link>
                                </div>)
                                : <div style={{ paddingTop: '20px' }}>
                                    <Menu selectedKeys={[current]} openKeys={openKeys} items={currentList} mode="inline" onClick={item => { onIntoData({ id: item.key, name: item.item.props.resname /* item.item后续版本会废弃,此处有警告  */ }); setState({ ...state, current: item.key, preLocation: item.key }) }} onOpenChange={openKeys => setState({ ...state, openKeys })} />
                                </div>
                        }
                    </Sider>
                }
                {/* <Content className={['myself', 'visual'].includes(current) ? style.mycontent : style.content}> */}
                <Content className={current === 'myself' ? style.mycontent : current === 'visual' ? style.vicontent : style.content}>
                    {
                        !['myself', 'visual'].includes(current) && !authority && <>
                            <div style={{ height: 40, marginTop: '24px', width: '100%', display: 'flex', alignItems: 'center', background: '#E5F1F5', boxShadow: '0px 4px 16px 0px rgba(0,136,255,0.15)' }}>
                                {
                                    scrolled > 0 && <div onClick={() => onPreviousClick()} style={{ cursor: 'default', lineHeight: '32px', width: '38px', textAlign: 'center', zIndex: 99, fontWeight: 550 }}> {"<"} </div>
                                }
                                <div ref={childRef} style={{ width: `calc(100% - ${scrolled ? 38 : 38}px - 38px)`, display: 'flex', alignItems: 'center', overflowX: 'hidden' }} >
                                    {
                                        data && data.map(item => <div onClick={() => onItemClick(item.id)} ref={ref => itemsRef.current[`ref_${item.id}`] = ref} key={item.id + 'tab'}>
                                            <div className={data.length > 1 ? style.itemClose : style.itemNormal} style={{ cursor: 'pointer', whiteSpace: 'nowrap', padding: '0px 16px', color: current === item.id ? '#0088FF' : '#273B45', lineHeight: '32px' }}>
                                                {item.name}
                                                {<span className={style.cancelButton} onClick={e => { e.stopPropagation(); onRemoveItem(item.id) }}>x</span>}
                                            </div>
                                        </div>)
                                    }
                                </div>
                                {
                                    <div onClick={() => (data.length > data.findIndex(k => k.id === current) + 1) && onNextClick()} style={{ cursor: 'default', lineHeight: '32px', width: '38px', textAlign: 'center', fontWeight: 550 }}> {">"} </div>
                                }
                            </div>
                            <div ref={positionRef} style={{ width: `calc(100% - ${scrolled ? 38 : 38}px - 38px)` }} />
                        </>
                    }
                    <div>
                        <Router routers={routers} current={current}/>
                        <Outlet />
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
}
export default connect(state => ({ ...state }), dispatch => ({ dispatch }))(PageLayout);