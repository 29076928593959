
// 权限字符串常量
export const PERMIT_STRING_LIST = {
    // iot-功能
    'IOT_FUNCTION': {
        'VIEW': 'F006004001001000', //查看
        'ADD': 'F006004001002000', //新增
        'UPDATE': 'F006004001003000', //编辑
        'DELETE': 'F006004001004000', //删除
    },
    'IOT_DRIVER': {
        'VIEW': 'F006004002001000', //查看
        'ADD': 'F006004002002000', //新增
        'UPDATE': 'F006004002003000',
        'DELETE': 'F006004002004000',
        'ADD_FUNCTION': 'F006004002005000',
        'UPDATE_FUNCTION': 'F006004002006000',
        'DELETE_FUNCTION': 'F006004002007000'
    },

    'IOT_DEVICE': {
        'VIEW': 'F006004003001000',//查看
        'ADD': 'F006004003002000',//新增
        'UPDATE': 'F006004003003000',//编辑
        'DELETE': 'F006004003004000',//删除
        'ACTIVE': 'F006004003005000',//激活
        'DISABLED': 'F006004003006000',//禁用/启用
        'UPDATE_TOPIC': 'F006004003008000',//topic编辑
        'ADD_SUB': 'F006004003009000',//注册子设备
        'ISSUED_CMD': 'F006004003013000',//命令查看
    },
    // 'IOT_DEVICE': [
    //     'F006004003001000',//查看
    //     'F006004003002000',//注册
    //     'F006004003003000',//编辑
    //     'F006004003004000',//删除
    //     'F006004003005000',//激活
    //     'F006004003006000',//禁用/启用
    //     'F006004003007000',//连接配置编辑
    //     'F006004003008000',//topic编辑
    //     'F006004003009000',//注册子设备
    //     'F006004003010000',//通信日志查看
    //     'F006004003011000',//通信日志导出
    //     'F006004003012000',//上报数据查看
    //     'F006004003013000',//命令查看
    //     'F006004003014000',//命令导出
    // ]

 //资产登记详情
 'ASSETS_CONFIG_INFO': {
    'ADD': 'F005008001001000', //登记
    'UP': 'F005008001002000', //导入
    'OUT': 'F005008001003000', //导出
    'EDIT': 'F005008001004000', //编辑
    'DELETE': 'F005008001005000', //删除

    'CONFIG': 'F005008001006000', //查看管理信息
    'VIDION': 'F005008001007000', //查看视觉信息
    'CHECK': 'F005008001008000', //查看盘点信息
    '实体信息': 'F005008001006001', //实体信息
    '生产信息': 'F005008001006002', //生产信息
    '购置信息': 'F005008001006003', //购置信息
    '标识信息': 'F005008001006004', //实体信息
    '分类信息': 'F005008001006005', //生产信息
    '价值信息': 'F005008001006006', //购置信息
    '归属信息': 'F005008001006007', //实体信息
    '业务信息': 'F005008001006008', //生产信息
    '孪生信息': 'F005008001006009', //购置信息
},
//资产盘点
'ASSETS_TAKE_STOCK':{
    'ADD':'F005008002001000', //新建目标
    'OUTTABLE':'F005008002002000', //导出基线表
    'OUTTAKE':'F005008002003000', //导出盘点报表
    'EDIT':'F005008002004000', //编辑
    'DELETE':'F005008002005000', //删除

    'TARGETGOAL':'F005008002006000', //目标行查看目标信息
    'TARGETQUEST':'F005008002007000', //目标行查看任务信息

    'QUESTTARGET':'F005008002008000', //任务行查看任务信息
    'QUESTTAKE':'F005008002009000', //任务行查看盘点信息
    'SETCLASSEDIT':'F005008003001000',//设置-分类编辑
},
//仪器登记详情
'INSTRUMENT_CONFIG_INFO': { 
    'BOARDBRANCH':'F005012002001000',//看板所有部门数据
    'BOARDOUT':'F005012002002000',//看板导出

    'STATISTIOVER':'F005012003001000',//统计仪器使用率总览
    'STATISTICSOVERBRANCH':'F005012003001001',//统计计仪器使用率总览 所有部门数据
    'STATISTICSOVEROUT':'F005012003001002',//统计计仪器使用率总览 导出
    'STATISTICSOVERLISTOFF':'F005012003001003',//统计计仪器使用率总览 列表离线原因
    'STATISTICSOVERLISTEDIT':'F005012003001004',//统计计仪器使用率总览 详情离线原因编辑
    'STATISTICSOVERLISTSDEL':'F005012003001005',//统计计仪器使用率总览 详情离线原因删除

    'STATISTIRECORD':'F005012003002000',//统计仪器使用率记录
    'STATISTIRECORDBRANCH':'F005012003002001',//统计统计仪器使用率记录 所有部门数据
    'STATISTIRECORDOUT':'F005012003002002',//统计统计仪器使用率记录 导出

    'STATISTIALARM':'F005012003003000',//统计使用率报警记录
    'STATISTIALARMBRANCH':'F005012003003001',//统计使用率报警记录 所有部门数据
    'STATISTIALARMOUT':'F005012003003002',//统计使用率报警记录 导出
    'STATISTIALARMEDIT':'F005012003003003',//统计使用率报警记录 编辑处理说明

    'ADDBRANCH':'F005012004009000',//登记所有部门数据
    'ADDNEWS':'F005012004010000',//登记统计信息
    'ADD': 'F005012004001000', //登记
    'UP': 'F005012004002000', //导入
    'OUT': 'F005012004003000', //导出
    'EDIT': 'F005012004004000', //编辑
    'DELETE': 'F005012004005000', //删除
    'CONFIG': 'F005012004006000', //查看管理信息
    'VIDION': 'F005012004007000', //查看视觉信息
    'CHECK': 'F005012004008000', //查看盘点信息
    '实体信息': 'F005012004006001', //实体信息
    '生产信息': 'F005012004006002', //生产信息
    '购置信息': 'F005012004006003', //购置信息
    '标识信息': 'F005012004006004', //实体信息
    '分类信息': 'F005012004006005', //生产信息
    '价值信息': 'F005012004006006', //购置信息
    '归属信息': 'F005012004006007', //实体信息
    '业务信息': 'F005012004006008', //生产信息
    '孪生信息': 'F005012004006009', //购置信息

    'TACTICSINSTR':'F005012001001000',//策略实验仪器
    'TACTICSINSTRBRANCH':'F005012001001001',//策略实验仪器部门
    'TACTICSCONFIG':'F005012001002000',//策略使用率配置
    'TACTICSCONFIGBRANCH':'F005012001002001',//策略使用率配置部门
},
//能耗登记详情
'ENERY_CONFIG_INFO': { 
    'ADD': 'F003009005001000', //登记
    'UP': 'F003009005002000', //导入
    'OUT': 'F003009005003000', //导出
    'EDIT': 'F003009005004000', //编辑
    'DELETE': 'F003009005005000', //删除


    'CONFIG': 'F003009005006000', //查看管理信息
    'VIDION': 'F003009005007000', //查看视觉信息
    'CHECK': 'F003009005008000', //查看盘点信息
    '实体信息': 'F003009005006001', //实体信息
    '生产信息': 'F003009005006002', //生产信息
    '购置信息': 'F003009005006003', //购置信息
    '标识信息': 'F003009005006004', //实体信息
    '分类信息': 'F003009005006005', //生产信息
    '价值信息': 'F003009005006006', //购置信息
    '归属信息': 'F003009005006007', //实体信息
    '业务信息': 'F003009005006008', //生产信息
    '孪生信息': 'F003009005006009', //购置信息
},

//运维维修工单
'REPAIR_ORDER_INFO':{
    'ADD': 'F005013001001000', //登记
    'EDIT': 'F005013001002000', //编辑
    'DELETE': 'F005013001003000', //删除
    'CHECK': 'F005013001004000', //查看盘点信息
    "ALL":'F005013001005000',//查看全部工单
},
//运维巡检工单
'INSPECTION_ORDER_INFO':{
    'INFO':'F005014001001000',//查看工单详情
    'SUSPEND':'F005014001002000',//中止工单
    'ALL':'F005014001003000',//查看全部工单
},
'INSPECTION_PLAN_INFO':{
    'ADD':'F005014002001000',//新建计划
    'EDIT':'F005014002002000',//编辑计划
    'STATUS':'F005014002003000',//启/停用巡检计划  
},
'INSPECTION_ITEN_INFO':{
    'ADDGROUP':'F005014003001000',//添加分组
    'EDITGROUP':'F005014003002000',//编辑分组
    'DELETEGROUP':'F005014003003000',//删除分组
    'ADDITEM':'F005014003004000',//添加项
    'EDITITEM':'F005014003005000',//编辑项
    'DELETEITEM':'F005014003006000',//删除项
    'STATUSITEM':'F005014003007000',//启/停用巡检项

},
//运维保养工单
'UPKREEP_ORDER_INFO':{
    'INFO':'F005015001001000',//查看工单详情
    'SUSPEND':'F005015001002000',//中止工单
    'ALL':'F005015001003000',//查看全部工单
},
'UPKREEP_PLAN_INFO':{
    'ADD':'F005015002001000',//新建计划
    'EDIT':'F005015002002000',//编辑计划
    'STATUS':'F005015002003000',//启/停用巡检计划  
},
'UPKREEP_ITEN_INFO':{
    'ADDGROUP':'F005015003001000',//添加分组
    'EDITGROUP':'F005015003002000',//编辑分组
    'DELETEGROUP':'F005015003003000',//删除分组
    'ADDITEM':'F005015003004000',//添加项
    'EDITITEM':'F005015003005000',//编辑项
    'DELETEITEM':'F005015003006000',//删除项
    'STATUSITEM':'F005015003007000',//启/停用巡检项

},

//运维校准工单
'CALIBRATE_ORDER_INFO':{
    'INFO':'F005016001001000',//查看工单详情
    'SUSPEND':'F005016001002000',//中止工单
    'ALL':'F005016001003000',//查看全部工单
},
'CALIBRATE_PLAN_INFO':{
    'ADD':'F005016002001000',//新建计划
    'EDIT':'F005016002002000',//编辑计划
    'STATUS':'F005016002003000',//启/停用巡检计划  
},
'CALIBRATE_ITEN_INFO':{
    'ADDGROUP':'F005016003001000',//添加分组
    'EDITGROUP':'F005016003002000',//编辑分组
    'DELETEGROUP':'F005016003003000',//删除分组
    'ADDITEM':'F005016003004000',//添加项
    'EDITITEM':'F005016003005000',//编辑项
    'DELETEITEM':'F005016003006000',//删除项
    'STATUSITEM':'F005016003007000',//启/停用巡检项

},

//共享管理
"SHARE_CONFIG_INFO":{
    "MEETADD":'F005011001001000',//会议预约新增，F005011001000000
    "MEETEDIT":'F005011001002000',//会议预约编辑，
    "MEETDEL":'F005011001003000',//会议预约删除，
    "MEETSTOP":'F005011001004000',//会议预约终止，
    "MEETCAL":'F005011001005000',//会议预约取消，

    "INSTRUMENTADD":'F005011003001000',//仪器预约新增，
    "INSTRUMENTEDIT":'F005011003002000',//仪器预约编辑，
    "INSTRUMENTDEL":'F005011003003000',//仪器预约删除，
    "INSTRUMENTSTOP":'F005011003004000',//仪器预约终止，
    "INSTRUMENTCAL":'F005011003005000',//仪器预约取消，
},


    // 后台管理
    backend_manager: "SYS000100100",	//后台管理 
    // CRM-客户
    crm_cus_new: "F00000100101",	//新增
    crm_cus_update: "F00000100102",	//编辑
    crm_cus_delete: "F00000100103",	//删除
    // CRM-项目
    crm_bus_new: "F00000100201",	//新增
    crm_bus_update: "F00000100202",	//编辑
    crm_bus_delete: "F00000100203",	//删除
    crm_bus_success: "F00000100204",	//中标通知
    crm_bus_close: "F00000100205",	//关闭
    crm_bus_persale: "F00000100206",	//售前申请
    crm_bus_price: "F00000100207",	//造价申请
    //CRM-合同
    crm_con_new: "F00000100301",	//新增
    crm_con_update: "F00000100302",	//编辑
    crm_con_delete: "F00000100303",	//删除
    //CRM-联系人
    crm_contact_new: "F00000100401",	//新增
    crm_contact_update: "F00000100402",	//编辑
    crm_contact_delete: "F00000100403",	//删除
    //CRM-项目任务,
    crm_bussiness_ersale: "F00000100501",	//分配售前申请
    crm_bussiness_rice: "F00000100502",	//分配造价申请
    //办公-审批
    office_approve_evection: "F00000200101",	//出差申请
    office_approve_reimbursement: "F00000200102",	//报销申请
    office_approve_pay: "F00000200103",	//付款申请
    office_approve_loan: "F00000200104",	//借款申请
    office_approve_makeoutinvoice: "F00000200105",	//发票申请
    office_approve_order: "F00000200106",	//订单申请
    //办公-采购
    office_supplier_supplier: "F00000300100",	//供应商
    office_supplier_new: "F00000300101",	//新增
    office_supplier_update: "F00000300102",	//编辑
    office_supplier_delete: "F00000300103",	//删除
    //办公-合同
    office_contract_new: "F00000300201",	//新增
    office_contract_update: "F00000300202",	//编辑
    office_contract_delete: "F00000300203",	//删除
    //办公-订单
    office_order_new: "F00000300301",	//新增
    office_order_update: "F00000300302",	//编辑
    office_order_delete: "F00000300303",	//删除
    //办公-财务
    office_finance_plan: "F00000400100",	//资金计划
    office_finance_new: "F00000400101",	//新增
    office_finance_update: "F00000400102",	//编辑
    office_finance_delete: "F00000400103",	//删除

    //首页
    main_main: "M00000100000", //首页
    main_workbenrch: "F00000500101", //工作台
    main_todo: "F00000500102", //我的待办
    //支付记录
    pay_record: "F00000500500", //支付记录
    // 预算编制
    budget_new: "F00000600101", //发起预算
    budget_edit: "F00000600102", //编制
    budget_submit: "F00000600103", //提交
    budget_close: "F00000600104", //关闭
    budget_modify: "F00000600105", //变更
    budget_affirm: "F00000600106", //最终确认
    // 订单
    order_submit: "F00000300302",  //提交
    order_edit: "F00000300303",  //编辑
    order_delete: "F00000300304",  //删除
    order_new: "F00000300301",  //新增主订单
    order_payout: "F00000300305",  //请款
    order_add: "F00000300306",  //追加减
    order_ticket: "F00000300306",  //请款

    // 请款
    payout_new: "F00000300401",  //发起付款
    payout_edit: "F00000300402",  //编辑
    payout_close: "F00000300403",  //关闭
    payout_pay: "F00000300404",  //支付

    // 票据提交
    ticket_query: "F00000200201",  //查看
    ticket_new: "F00000200202",  //新增
    ticket_edit: "F00000200203",  //编辑
    ticket_delete: "F00000200204",  //删除
    ticket_print: "F00000200205",  //打印
    ticket_close: "F00000200206",  //关闭

    // 还款提交
    payment_query: "F00000200301",  //查看
    payment_new: "F00000200302",  //新增
    payment_edit: "F00000200303",  //编辑
    payment_delete: "F00000200304",  //删除
    payment_print: "F00000200305",  //打印
    payment_close: "F00000200306",  //关闭
}





