// export const baseUrl = 'http://192.168.1.247:18001/gateway/' //16001开发 18001测试
// export const ssoUrl = 'http://192.168.1.247:58831'
// export const SystemName = '基数智慧实验室'//系统名称
// export const SystemLogo = require('../../../public/static/img/logojs.svg')//系统logo

// export const baseUrl = 'http://192.168.1.235:18001/gateway/' //235环境
// export const ssoUrl = 'http://192.168.1.235:58831'
// export const SystemName = '基数智慧实验室'//系统名称
// export const SystemLogo = require('../../../public/static/img/logojs.svg')//系统logo

export const baseUrl = 'http://demo.labs-b.cn:18001/gateway/' //演示环境
export const ssoUrl = 'http://demo.labs-b.cn:58901'
export const SystemName = '基数智慧实验室'//系统名称
export const SystemLogo = require('../../../public/static/img/logo2.svg')//系统logo
// export const baseUrl = 'http://in3labms.shbiochip.com:18001/gateway/' //众创演示环境
// export const ssoUrl = 'http://in3labms.shbiochip.com:58831'
// export const SystemName = '生物芯片开放创新中心'
// export const SystemLogo = require('../../../public/static/img/zclogo.svg')
// export const baseUrl = 'http://xsyy.labs-b.cn:58001/gateway/' // 先声药业演示环境
// export const ssoUrl = 'http://xsyy.labs-b.cn:58831'
// export const SystemName = '蓝西智慧实验室'
// export const SystemLogo = require('../../../public/static/img/logo.svg')
// export const baseUrl = 'https://place.concoragroup.cn:17002/gateway/' //colab
// export const ssoUrl = 'http://colab.labs-b.cn:58831'
// export const SystemName = '综合管理平台'//系统名称
// export const SystemLogo = require('../../../public/static/img/colabslogo.svg')//系统logo

// export const baseUrl = 'http://10.30.55.25:18001/gateway/' //先声生产
// // export const ssoUrl = 'http://10.30.55.25:58831'
// export const SystemName = '基数智慧实验室'//系统名称
// export const SystemLogo = require('../../../public/static/img/logojs.svg')//系统logo

// export const baseUrl = 'http://192.168.1.196:18001/gateway/' //196测试
// export const ssoUrl = 'http://192.168.1.196:58831'
// export const SystemName = '基数智慧实验室'//系统名称
// export const SystemLogo = require('../../../public/static/img/logojs.svg')//系统logo