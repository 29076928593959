import React  from 'react';
import { HashRouter } from 'react-router-dom'
import PageLayout from './pageLayout';
import { Provider } from 'react-redux'
import store from '../../store';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import 'antd/dist/antd.less';
import './index.less';
import '../../styles/antd.less';

const App = () => {
    
    return (
        <ConfigProvider locale={zhCN} >
       
            <Provider store={store}>
                <HashRouter>
                    <PageLayout />
                </HashRouter>
            </Provider>
    
          
        </ConfigProvider>
    )
}
export default App